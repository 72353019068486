import posthog, { type PostHog } from "posthog-js";

export default defineNuxtPlugin((nuxtApp) => {
	const { $logger } = useNuxtApp();

	const config = useRuntimeConfig();
	const isDevelopment = import.meta.env.MODE === "development";

	const client = !isDevelopment
		? (posthog.init(config.public.posthog.publicKey, {
				api_host: "https://app.posthog.com",
				loaded: (posthog) => {
					if (config.public.posthog.debug) posthog.debug();
				},
		  }) as PostHog)
		: null;

	// Make sure that pageviews are captured with each route change
	const router = useRouter();
	router.afterEach((to) => {
		if (isDevelopment) return;
		if (to.path.includes("/admin")) return;
		client?.capture("$pageview", {
			current_url: to.fullPath,
		});
	});

	return {
		provide: {
			posthog: {
				track(event: string, data: any) {
					try {
						if (!isDevelopment) client?.capture(event, data);
					} catch (error) {
						$logger.error(error);
					}
				},
			},
		},
	};
});
