import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import admin_45auth_45global from "/vercel/path0/middleware/adminAuth.global.ts";
import session_45global from "/vercel/path0/middleware/session.global.ts";
import touch_45global from "/vercel/path0/middleware/touch.global.ts";
import username_45global from "/vercel/path0/middleware/username.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  admin_45auth_45global,
  session_45global,
  touch_45global,
  username_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}