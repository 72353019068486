import MobileDetect from "mobile-detect";

export default defineNuxtRouteMiddleware(async (to, from) => {
	if (process.server) {
		const touch = useState("touch", () => false);

		if (process.server) {
			const userAgent = useRequestHeaders()["user-agent"];
			if (userAgent) {
				const detect = new MobileDetect(userAgent);
				touch.value = detect.mobile() ? true : false;
			}
		}
	}
});
