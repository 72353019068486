import routes from "../utils/routes";
import { useUser } from "../stores/user";

export default defineNuxtRouteMiddleware(async (to, from) => {
	if (process.server) {
		if (to.path.includes("auth") || to.path.includes("api")) {
			return;
		}

		if (to.path.includes("admin")) {
			if (to.path === routes.admin.login) {
				return;
			}

			const cookie = useCookie(useRuntimeConfig().public.cookieNamespace);

			if (!cookie.value) {
				// if user for some reason has no cookie, redirect to admin login
				return navigateTo(routes.admin.login);
			}

			try {
				// if user has cookie, let's check it
				const { me } = useUser();
				const { role } = await me();
				// user has no admin rights
				if (role !== "admin") {
					throw new Error("User has no admin rights");
				}
				// all good, user is authenticated and has admin rights, we let him go on the page
			} catch (error) {
				// user is not authenticated really, let's delete his wrong cookies
				console.error(error);

				// delete the cookie
				cookie.value = null;

				return navigateTo(routes.admin.login);
			}
		}
	}
});
