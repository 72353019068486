export default defineNuxtRouteMiddleware(async (to, from) => {
	if (process.server) {
		if (to.name === "username") {
			const param = to.params.username;
			// if username has no @, it's not a valid username
			if (param && !param?.includes("@")) {
				throw createError({ statusCode: 404, statusMessage: "Page Not Found" });
			}
		}
	}
});
