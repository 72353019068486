import { default as _91username_93Szrx3A3CuzMeta } from "/vercel/path0/pages/[username].vue?macro=true";
import { default as apple_45loginXJiYcob1MpMeta } from "/vercel/path0/pages/admin/apple-login.vue?macro=true";
import { default as _91id_93PUiaF4GjKHMeta } from "/vercel/path0/pages/admin/categories/[id].vue?macro=true";
import { default as createlSaucNWAjXMeta } from "/vercel/path0/pages/admin/categories/create.vue?macro=true";
import { default as index0YhAgkahE3Meta } from "/vercel/path0/pages/admin/categories/index.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as loginnB6F0vreEVMeta } from "/vercel/path0/pages/admin/login.vue?macro=true";
import { default as index0ASaeaz01aMeta } from "/vercel/path0/pages/admin/post-likes/index.vue?macro=true";
import { default as indexhAJObY1O9oMeta } from "/vercel/path0/pages/admin/post-responses/index.vue?macro=true";
import { default as indexbVsAzB90jZMeta } from "/vercel/path0/pages/admin/post-shares/index.vue?macro=true";
import { default as indexD4fwWXKk14Meta } from "/vercel/path0/pages/admin/post-views/index.vue?macro=true";
import { default as _91id_93n3GxNGM5zQMeta } from "/vercel/path0/pages/admin/posts/[id].vue?macro=true";
import { default as createlcOSXWP82JMeta } from "/vercel/path0/pages/admin/posts/create.vue?macro=true";
import { default as indexCJmAiJdkFbMeta } from "/vercel/path0/pages/admin/posts/index.vue?macro=true";
import { default as profileghNdkuvdbnMeta } from "/vercel/path0/pages/admin/profile.vue?macro=true";
import { default as indexQEMtDjtLEbMeta } from "/vercel/path0/pages/admin/tokens/index.vue?macro=true";
import { default as _91id_939f4NtHSsVaMeta } from "/vercel/path0/pages/admin/users/[id].vue?macro=true";
import { default as indexgxZ1ikbUBwMeta } from "/vercel/path0/pages/admin/users/index.vue?macro=true";
import { default as componentsRfOnVfXFhhMeta } from "/vercel/path0/pages/components.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as _91id_93WTvcjKotbPMeta } from "/vercel/path0/pages/share/[id].vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
export default [
  {
    name: _91username_93Szrx3A3CuzMeta?.name ?? "username",
    path: _91username_93Szrx3A3CuzMeta?.path ?? "/:username()",
    meta: _91username_93Szrx3A3CuzMeta || {},
    alias: _91username_93Szrx3A3CuzMeta?.alias || [],
    redirect: _91username_93Szrx3A3CuzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[username].vue").then(m => m.default || m)
  },
  {
    name: apple_45loginXJiYcob1MpMeta?.name ?? "admin-apple-login",
    path: apple_45loginXJiYcob1MpMeta?.path ?? "/admin/apple-login",
    meta: apple_45loginXJiYcob1MpMeta || {},
    alias: apple_45loginXJiYcob1MpMeta?.alias || [],
    redirect: apple_45loginXJiYcob1MpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/apple-login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PUiaF4GjKHMeta?.name ?? "admin-categories-id",
    path: _91id_93PUiaF4GjKHMeta?.path ?? "/admin/categories/:id()",
    meta: _91id_93PUiaF4GjKHMeta || {},
    alias: _91id_93PUiaF4GjKHMeta?.alias || [],
    redirect: _91id_93PUiaF4GjKHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: createlSaucNWAjXMeta?.name ?? "admin-categories-create",
    path: createlSaucNWAjXMeta?.path ?? "/admin/categories/create",
    meta: createlSaucNWAjXMeta || {},
    alias: createlSaucNWAjXMeta?.alias || [],
    redirect: createlSaucNWAjXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/categories/create.vue").then(m => m.default || m)
  },
  {
    name: index0YhAgkahE3Meta?.name ?? "admin-categories",
    path: index0YhAgkahE3Meta?.path ?? "/admin/categories",
    meta: index0YhAgkahE3Meta || {},
    alias: index0YhAgkahE3Meta?.alias || [],
    redirect: index0YhAgkahE3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexG7R2Mya2P4Meta?.name ?? "admin",
    path: indexG7R2Mya2P4Meta?.path ?? "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    alias: indexG7R2Mya2P4Meta?.alias || [],
    redirect: indexG7R2Mya2P4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: loginnB6F0vreEVMeta?.name ?? "admin-login",
    path: loginnB6F0vreEVMeta?.path ?? "/admin/login",
    meta: loginnB6F0vreEVMeta || {},
    alias: loginnB6F0vreEVMeta?.alias || [],
    redirect: loginnB6F0vreEVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: index0ASaeaz01aMeta?.name ?? "admin-post-likes",
    path: index0ASaeaz01aMeta?.path ?? "/admin/post-likes",
    meta: index0ASaeaz01aMeta || {},
    alias: index0ASaeaz01aMeta?.alias || [],
    redirect: index0ASaeaz01aMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/post-likes/index.vue").then(m => m.default || m)
  },
  {
    name: indexhAJObY1O9oMeta?.name ?? "admin-post-responses",
    path: indexhAJObY1O9oMeta?.path ?? "/admin/post-responses",
    meta: indexhAJObY1O9oMeta || {},
    alias: indexhAJObY1O9oMeta?.alias || [],
    redirect: indexhAJObY1O9oMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/post-responses/index.vue").then(m => m.default || m)
  },
  {
    name: indexbVsAzB90jZMeta?.name ?? "admin-post-shares",
    path: indexbVsAzB90jZMeta?.path ?? "/admin/post-shares",
    meta: indexbVsAzB90jZMeta || {},
    alias: indexbVsAzB90jZMeta?.alias || [],
    redirect: indexbVsAzB90jZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/post-shares/index.vue").then(m => m.default || m)
  },
  {
    name: indexD4fwWXKk14Meta?.name ?? "admin-post-views",
    path: indexD4fwWXKk14Meta?.path ?? "/admin/post-views",
    meta: indexD4fwWXKk14Meta || {},
    alias: indexD4fwWXKk14Meta?.alias || [],
    redirect: indexD4fwWXKk14Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/post-views/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93n3GxNGM5zQMeta?.name ?? "admin-posts-id",
    path: _91id_93n3GxNGM5zQMeta?.path ?? "/admin/posts/:id()",
    meta: _91id_93n3GxNGM5zQMeta || {},
    alias: _91id_93n3GxNGM5zQMeta?.alias || [],
    redirect: _91id_93n3GxNGM5zQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: createlcOSXWP82JMeta?.name ?? "admin-posts-create",
    path: createlcOSXWP82JMeta?.path ?? "/admin/posts/create",
    meta: createlcOSXWP82JMeta || {},
    alias: createlcOSXWP82JMeta?.alias || [],
    redirect: createlcOSXWP82JMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/posts/create.vue").then(m => m.default || m)
  },
  {
    name: indexCJmAiJdkFbMeta?.name ?? "admin-posts",
    path: indexCJmAiJdkFbMeta?.path ?? "/admin/posts",
    meta: indexCJmAiJdkFbMeta || {},
    alias: indexCJmAiJdkFbMeta?.alias || [],
    redirect: indexCJmAiJdkFbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/posts/index.vue").then(m => m.default || m)
  },
  {
    name: profileghNdkuvdbnMeta?.name ?? "admin-profile",
    path: profileghNdkuvdbnMeta?.path ?? "/admin/profile",
    meta: profileghNdkuvdbnMeta || {},
    alias: profileghNdkuvdbnMeta?.alias || [],
    redirect: profileghNdkuvdbnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/profile.vue").then(m => m.default || m)
  },
  {
    name: indexQEMtDjtLEbMeta?.name ?? "admin-tokens",
    path: indexQEMtDjtLEbMeta?.path ?? "/admin/tokens",
    meta: indexQEMtDjtLEbMeta || {},
    alias: indexQEMtDjtLEbMeta?.alias || [],
    redirect: indexQEMtDjtLEbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/tokens/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939f4NtHSsVaMeta?.name ?? "admin-users-id",
    path: _91id_939f4NtHSsVaMeta?.path ?? "/admin/users/:id()",
    meta: _91id_939f4NtHSsVaMeta || {},
    alias: _91id_939f4NtHSsVaMeta?.alias || [],
    redirect: _91id_939f4NtHSsVaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgxZ1ikbUBwMeta?.name ?? "admin-users",
    path: indexgxZ1ikbUBwMeta?.path ?? "/admin/users",
    meta: indexgxZ1ikbUBwMeta || {},
    alias: indexgxZ1ikbUBwMeta?.alias || [],
    redirect: indexgxZ1ikbUBwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: componentsRfOnVfXFhhMeta?.name ?? "components",
    path: componentsRfOnVfXFhhMeta?.path ?? "/components",
    meta: componentsRfOnVfXFhhMeta || {},
    alias: componentsRfOnVfXFhhMeta?.alias || [],
    redirect: componentsRfOnVfXFhhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/components.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQl0qlywOJTMeta?.name ?? "privacy-policy",
    path: privacy_45policyQl0qlywOJTMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    alias: privacy_45policyQl0qlywOJTMeta?.alias || [],
    redirect: privacy_45policyQl0qlywOJTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WTvcjKotbPMeta?.name ?? "share-id",
    path: _91id_93WTvcjKotbPMeta?.path ?? "/share/:id()",
    meta: _91id_93WTvcjKotbPMeta || {},
    alias: _91id_93WTvcjKotbPMeta?.alias || [],
    redirect: _91id_93WTvcjKotbPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/share/[id].vue").then(m => m.default || m)
  },
  {
    name: termsq5qlZHSSonMeta?.name ?? "terms",
    path: termsq5qlZHSSonMeta?.path ?? "/terms",
    meta: termsq5qlZHSSonMeta || {},
    alias: termsq5qlZHSSonMeta?.alias || [],
    redirect: termsq5qlZHSSonMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms.vue").then(m => m.default || m)
  }
]