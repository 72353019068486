import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/plugins/bugsnag.ts";
import facebook_client_CFH3L0Sd4I from "/vercel/path0/plugins/facebook.client.ts";
import logger_kEXgbufg9p from "/vercel/path0/plugins/logger.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import restapi_6OtAf5Zq7n from "/vercel/path0/plugins/restapi.ts";
import tracker_client_xl3Vj6nUnL from "/vercel/path0/plugins/tracker.client.ts";
import vercel_client_xTjjRKhane from "/vercel/path0/plugins/vercel.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  bugsnag_tkMQfOjcVZ,
  facebook_client_CFH3L0Sd4I,
  logger_kEXgbufg9p,
  posthog_client_fUucxKWhZE,
  restapi_6OtAf5Zq7n,
  tracker_client_xl3Vj6nUnL,
  vercel_client_xTjjRKhane
]