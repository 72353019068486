import { defineStore } from "pinia";
import type { Response } from "~/server/api/auth/me.get";

export const useUser = defineStore("user", () => {
	const id = ref<string | undefined>();
	const role = ref<Roles | undefined>();
	const touch = ref<boolean>(false);

	async function me(): Promise<Response> {
		const cookie = useCookie(useRuntimeConfig().public.cookieNamespace);

		const { id: _id, role: _role } = await $fetch<Response>(`/api/auth/me`, {
			headers: {
				Authorization: cookie.value ? `Bearer ${cookie.value}` : "",
			},
		});

		id.value = _id;
		role.value = _role;

		return { id: _id, role: _role };
	}

	return {
		touch,
		me,
		id,
		role,
	};
});
