// @ts-nocheck

export default defineNuxtPlugin(() => {
	const { $logger } = useNuxtApp();
	const config = useRuntimeConfig();
	const router = useRouter();
	const isDevelopment = import.meta.env.MODE === "development";

	// Setup script
	!(function (f, b, e, v, n, t, s) {
		if (f.fbq) return;
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = "2.0";
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

	if (!isDevelopment) fbq("init", config.public.facebook.pixelId);

	// Listen on route changes
	if (router) {
		router.afterEach(({ path }) => {
			// Prevent admin views
			if (isDevelopment) return;
			if (path.includes("/admin")) return;
			fbq("track", "PageView");
		});
	}

	return {
		provide: {
			facebook: {
				track(event: string, data: any) {
					try {
						if (!isDevelopment) fbq("trackCustom", event, data);
					} catch (error) {
						$logger.error(error);
					}
				},
			},
		},
	};
});
